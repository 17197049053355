import { AgGridReact } from 'ag-grid-react';
import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Input,
    Menu,
    message,
    notification,
    Row,
    Select,
    Spin,
} from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { EntitlementAuditReport, EntitlementFilter } from 'src/__generated__/types';
import { DropDownSelectType, EntitlementFormProps } from './EntitlementInterfaces';
import { getAgGridLocalization, PersonalizeGridView } from '../../../pages/info-portal/components/grid/GridUtil';
import { GridApi } from 'ag-grid-community';
import { useGetEntitlementAuditReportQuery } from './queries.generated';
import EntitlementGroupDropDown from './EntitlementGroupDropDown';
import B2XRoleDropDown from './B2XRoleDropDown';
import ObjectClassComponent from './ObjectClassDropDown';
import { getDateFormatOrDefault } from '../../../pages/users/UserFormatPreferenceServices';
import { LabelLocation } from '../../../pages/info-portal/InfoPortalInterfaces';
import { AuditDateFromToSelection } from './DateUtils';
import CompanyCodeDropDown from './CompanyCodeDropDown';
import EntitlementTypeDropDown from './EntitlementTypeDropDown';
import { ClearOutlined, DownOutlined, ReloadOutlined, SaveOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../main/hooks';
import { updateEntitlementAuditFilters } from './redux/IStoredEntitlementsSlice';

const ENTITLEMENT_RULES_TABLE_HEADERS = () => [
    {
        headerName: 'Rule Id',
        field: 'ruleId',
        colId: 'ruleId',
        width: 100,
        filter: 'agMultiColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
    },
    {
        headerName: 'Latest Audit Time',
        field: 'latestAuditTime',
        colId: 'latestAuditTime',
        width: 200,
        filter: 'agDateColumnFilter',
    },
    { headerName: 'Entitlement', field: 'entitlement', colId: 'entitlement', filter: 'agMultiColumnFilter' },
    {
        headerName: 'Entitlement Description',
        field: 'entitlementDescription',
        colId: 'entitlementDescription',
        filter: 'agMultiColumnFilter',
        width: 400,
    },
];

const ENTITLEMENT_DETAIL_TABLE_HEADERS = () => [
    {
        headerName: 'Rule Id',
        field: 'ruleId',
        colId: 'ruleId',
        width: 100,
        filter: 'agMultiColumnFilter',
    },
    { headerName: 'Audit action', field: 'auditAction', colId: 'auditAction', filter: 'agMultiColumnFilter' },
    {
        headerName: 'Allowed Or Blocked',
        field: 'allowedOrBlocked',
        colId: 'allowedOrBlocked',
        filter: 'agMultiColumnFilter',
    },
    // { headerName: "Partner List", field: "partner", colId: "partner" },
    { headerName: 'B2X Roles', field: 'b2xRole', colId: 'b2xRole', filter: 'agMultiColumnFilter' },
    { headerName: 'Department Codes', field: 'department', colId: 'department', filter: 'agMultiColumnFilter' },
    { headerName: 'Company Codes', field: 'company', colId: 'company', filter: 'agMultiColumnFilter' },
    { headerName: 'Object Class', field: 'objectClass', colId: 'objectClass', filter: 'agMultiColumnFilter' },
    { headerName: 'User ID', field: 'userId', colId: 'userId', filter: 'agMultiColumnFilter' },
    {
        headerName: 'Other Entitlements on Rule',
        field: 'otherEntitlementsOnRule',
        colId: 'otherEntitlementsOnRule',
        filter: 'agMultiColumnFilter',
    },
    { headerName: 'Audit Time', field: 'auditTime', colId: 'auditTime', filter: 'agMultiColumnFilter' },
    { headerName: 'Insert User', field: 'insertUser', colId: 'insertUser', filter: 'agMultiColumnFilter' },
    { headerName: 'Insert Time', field: 'insertTime', colId: 'insertTime', filter: 'agMultiColumnFilter' },
    { headerName: 'Update User', field: 'updateUser', colId: 'updateUser', filter: 'agMultiColumnFilter' },
    { headerName: 'Update Time', field: 'updateTime', colId: 'updateTime', filter: 'agMultiColumnFilter' },
];

const EntitlementAuditComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();
    const [searchFilter, setSearchFilter] = useState<EntitlementFilter>({});
    const [detailGridApi, setDetailGridApi] = useState<GridApi>(undefined);
    const [masterGridApi, setMasterGridApi] = useState<GridApi>(undefined);
    const [selectedDetailRow, setSelectedDetailRow] = useState(null);
    const [selectedMasterRow, setSelectedMasterRow] = useState(null);
    const [vertical, _] = useState<boolean>(false);
    const [entitlementGroupSelected, setEntitlementGroupSelected] = useState<string[]>([]);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [entitlementAuditReport, setEntitlementAuditReport] = useState<EntitlementAuditReport[]>([]);
    const entitlementState = useAppSelector((state) => state.entitlements);
    const dispatch = useAppDispatch();

    const masterGridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'entitlement_maintenance_master_audit_ag_grid_personalized_view',
            ENTITLEMENT_RULES_TABLE_HEADERS()
        )
    );

    const detailGridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'entitlement_maintenance_detail_audit_ag_grid_personalized_view',
            ENTITLEMENT_DETAIL_TABLE_HEADERS()
        )
    );

    const onMasterGridReady = (params) => {
        setMasterGridApi(params.api);
        if (masterGridView.current.hasPersonalizedView) {
            masterGridView.current.initPersonalizedView(params.api);
        }
        // else
        // {
        //     params.api.sizeColumnsToFit();
        // }
    };

    const onDetailGridReady = (params) => {
        setDetailGridApi(params.api);
        if (detailGridView.current.hasPersonalizedView) {
            detailGridView.current.initPersonalizedView(params.api);
        }
    };

    const handleFormSearch = (values) => {
        console.log('Form search Values:', form.getFieldsValue());
        // Handle form search logic here
        setSearchFilter({
            ...form.getFieldsValue(),
            auditTimeFrom: form.getFieldValue('auditDate')?.at(0),
            auditTimeTo: form.getFieldValue('auditDate')?.at(1),
        });
    };

    const onSave = (_: any) => {
        masterGridView?.current?.savePersonalizedView();
        detailGridView?.current?.savePersonalizedView();
        notification.info({ message: props.intl.formatMessage({ id: 'report-result-preferences-saved' }) });
    };

    const onApplyPerson = (_: any) => {
        masterGridView?.current?.applyPersonalizedView();
        detailGridView?.current?.applyPersonalizedView();
    };

    const onReset = (_: any) => {
        masterGridApi?.setFilterModel(null);
        detailGridApi?.setFilterModel(null);
        masterGridView?.current?.reset();
        detailGridView?.current?.reset();
    };

    const downIcon = () => <DownOutlined />;

    const menu = (
        <Menu>
            <Menu.Item
                key="3"
                disabled={
                    !masterGridView.current.hasPersonalizedView() && !detailGridView.current.hasPersonalizedView()
                }
            >
                <UserOutlined onClick={onApplyPerson} />
                <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
                    {props.intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">
                <SaveOutlined onClick={onSave} />

                <a data-testid={'info-portal-save'} onClick={onSave}>
                    {props.intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
                </a>
            </Menu.Item>
        </Menu>
    );

    const onFieldChange = (e) => {
        dispatch(updateEntitlementAuditFilters(form.getFieldsValue()));
    }

    const {
        data: entitlementAuditReportRowData,
        loading: entitlementAuditReportLoading,
        error: entitlementAuditReportError,
        refetch: entitlementAuditRefresh,
    } = useGetEntitlementAuditReportQuery({
        variables: {
            entitlementFilter: searchFilter,
        },
    });

    const selectMasterRow = () => {
        console.log('Selected Master Row:');
        console.dir(masterGridApi!.getSelectedRows());
        const selectedRows = masterGridApi!.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedMasterRow(selectedRows[0]);
        } else {
            setSelectedMasterRow(null);
        }
    };

    const selectDetailRow = () => {
        console.log('Selected Detail Row:');
        console.dir(detailGridApi!.getSelectedRows());
        const selectedRows = detailGridApi!.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedDetailRow(selectedRows[0]);
        } else {
            setSelectedDetailRow(null);
        }
    };

    const detailGridOptions = useMemo(
        () => ({
            rowSelection: 'single',
            suppressRowClickSelection: true,
            enableRangeSelection: false,
            pagination: true,
            rowClass: 'agGridRows',
            paginationAutoPageSize: true,
            onRowSelected: selectDetailRow,
            onGridReady: onDetailGridReady,
            // columnDefs: ENTITLEMENT_DETAIL_TABLE_HEADERS,
            columnDefs: detailGridView.current.getView(),
            api: detailGridApi,
            defaultColDef: {
                sortable: true,
                flex: 1,
                resizable: true,
            },
        }),
        []
    );

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions,
            getDetailRowData: (params) =>
                params.successCallback(params.data.details === null ? [] : params.data.details),
        }),
        []
    );

    const onEntitlementGroupSelect = () => {
        setEntitlementGroupSelected(form.getFieldValue('entitlementGroup'));
        onFieldChange(null);
    };

    const onEntitlementGroupClear = (key) => {
        setEntitlementGroupSelected([]);
    };

    if (entitlementAuditReportError) {
        message.error(`Error! ${entitlementAuditReportError.message}`);
        console.log(`error: ${entitlementAuditReportError.message}`);
    }

    
    React.useEffect(() => {
        props.onLoading(entitlementAuditReportLoading);
        if (!entitlementAuditReportLoading && entitlementAuditReportRowData) {
            setEntitlementAuditReport(entitlementAuditReportRowData.getEntitlementAuditReport);
        }
        if (entitlementState?.entitlementSchema?.entitlementAuditFilter)
        {
            form.setFieldsValue(entitlementState?.entitlementSchema?.entitlementAuditFilter);
        }
    }, [entitlementAuditReportLoading, entitlementAuditReportRowData]);

    const refreshData = () => {
        setRefreshLoading(true);
        entitlementAuditRefresh().then(({ data }) => {
            setEntitlementAuditReport(data.getEntitlementAuditReport);
            if (masterGridApi) {
                masterGridApi.setRowData(data.getEntitlementAuditReport);
            }
            setRefreshLoading(false);
        }).catch(() => {
            setRefreshLoading(false);
        });
    };

    const clearFilters = () => {
        form.resetFields();
        dispatch(updateEntitlementAuditFilters(null));
        setEntitlementGroupSelected([]);
    }


    return (
            <Spin spinning={entitlementAuditReportLoading || refreshLoading}>
                <Card>
                    <Form form={form} layout="vertical">
                        <Row gutter={24} className={'pl20'}>
                            <Col span={1}>
                                <Button type="primary" icon={<SearchOutlined/>} onClick={handleFormSearch} className={'mr5'}>
                                    Search
                                </Button>
                            </Col>
                            <Col span={1}>
                                <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}>
                                    Refresh
                                </Button>
                            </Col>
                            <Col span={1}>
                                <Button type="default" icon={<ClearOutlined />} onClick={clearFilters}>
                                    Clear Filters
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={4}>
                                <AuditDateFromToSelection
                                    dateFormatter={getDateFormatOrDefault(props.currentUser)}
                                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                                    displayColon={!vertical}
                                    currentUser={props.currentUser}
                                    intl={props.intl}
                                    distinctEntitlements={props.distinctEntitlements}
                                    form={form}
                                    initialization={null}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item name="insertUser" label="Insert User">
                                    <Input placeholder="User that created entitlement" allowClear={true} onChange={onFieldChange}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="updateUser" label="Update User">
                                    <Input placeholder="User that upated entitlement" allowClear={true} onChange={onFieldChange}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Collapse ghost={true}>
                            <Collapse.Panel header="Advanced Search" key="1">
                                <Row gutter={24} className={'pl20'}>
                                    <Col span={4}>
                                        <Form.Item name="userId" label="User Id">
                                            <Input placeholder="User Id" allowClear={true} onChange={onFieldChange}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="department" label="Department Code">
                                            <Input placeholder="Department Code" allowClear={true} onChange={onFieldChange}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <CompanyCodeDropDown
                                            intl={props.intl}
                                            currentUser={props.currentUser}
                                            distinctEntitlements={props.distinctEntitlements}
                                            selectType={DropDownSelectType.Multiple}
                                            onSelect={onFieldChange}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <EntitlementGroupDropDown
                                            setEntitlementGroupSelected={onEntitlementGroupSelect}
                                            dropDownSelectType={DropDownSelectType.Multiple}
                                            onEntitlementGroupClear={onEntitlementGroupClear}
                                            onGroupSelect={onFieldChange}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <EntitlementTypeDropDown
                                            entitlementGroupSelected={entitlementGroupSelected}
                                            form={form}
                                            options={[]}
                                            onChange={onFieldChange}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="allowedOrBlocked" label="Allowed Or Blocked">
                                            <Select placeholder="Allowed Or Blocked" allowClear={true} onSelect={onFieldChange}>
                                                <Select.Option value="A">Allowed</Select.Option>
                                                <Select.Option value="B">Blocked</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <B2XRoleDropDown onLoading={props.onLoading} setB2XRoleSelected={onFieldChange}/>
                                    </Col>
                                    <Col span={4}>
                                        <ObjectClassComponent onLoading={props.onLoading} setObjectClassSelected={onFieldChange}/>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Form>
                    {/* <Row gutter={24} style={{ paddingTop: '5px' }}>
                    <Col style={{ paddingRight: '5px' }}>
                        <Dropdown.Button
                            data-testid={'view-result-customization'}
                            overlay={menu}
                            trigger={['click']}
                            icon={downIcon()}
                            size={'middle'}
                        >
                            <EyeOutlined />
                            {props.intl.formatMessage({
                                id: 'info-portal-view',
                                defaultMessage: 'View',
                            })}
                        </Dropdown.Button>
                    </Col>
                    <Col style={{ paddingRight: '5px' }}>
                        <Tooltip
                            title={props.intl.formatMessage({ id: 'reset-filter' })}>
                            <Button
                                data-testid={'view-result-customization-reset'}
                                onClick={onReset} size={'middle'}
                                icon={<ReloadOutlined
                                    className={moduleStyles.simpleFlipRefresh} />}>
                                {props.intl.formatMessage({ id: 'info-portal-reset' })}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row> */}
                    <Row gutter={24}>
                        <Col span={20} className="mb5">
                            <div
                                style={{ flex: 'auto', height: '500px', paddingTop: '0px' }}
                                className="ag-theme-balham"
                            >
                                <AgGridReact
                                    defaultColDef={{
                                        enableValue: true,
                                        sortable: true,
                                        resizable: true,
                                    }}
                                    // columnDefs={ENTITLEMENT_RULES_TABLE_HEADERS()}
                                    columnDefs={masterGridView.current.getView()}
                                    rowData={entitlementAuditReport || []}
                                    localeText={getAgGridLocalization(props.intl)}
                                    sideBar={false}
                                    api={masterGridApi}
                                    rowClass="agGridRows"
                                    rowSelection="single"
                                    suppressMenuHide={true}
                                    debug={false}
                                    pagination={true}
                                    paginationPageSize={15}
                                    suppressRowClickSelection={true}
                                    onGridReady={onMasterGridReady}
                                    onRowSelected={selectMasterRow}
                                    masterDetail={true}
                                    detailCellRendererParams={detailCellRendererParams}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Spin>
    );
};

export default EntitlementAuditComponent;
