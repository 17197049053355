import React from 'react';
import EntitlementCreateComponent from './EntitlementCreateComponent';
import { EntitlementEditFormProps } from './EntitlementInterfaces';
import { Button, Modal } from 'antd';

const EntitlementEditRuleComponent: React.FunctionComponent<EntitlementEditFormProps> = (props) => {
    return (
        <Modal
            title="Edit Entitlement"
            open={props.showModal}
            onCancel={props.onCancel}
            footer={[
                <Button key="back" onClick={props.onCancel}>
                    Close
                </Button>,
            ]}
            width={2000}
        >
            <EntitlementCreateComponent
                intl={props.intl}
                currentUser={props.currentUser}
                onLoading={props.onLoading}
                entitlementRuleToEdit={props.entitlementRuleToEdit}
                distinctEntitlements={props.distinctEntitlements}
                onCancel={props.onCancel}
            />
        </Modal>
    );
};

export default EntitlementEditRuleComponent;