import { AgGridReact } from 'ag-grid-react';
import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { IAppComponentProps, PortalUser } from '../../../components';
import { getAgGridLocalization } from '../../info-portal/components/grid/GridUtil';
import { GridApi } from 'ag-grid-community';
import { useGetEntitlementReportByRuleIdsQuery } from './queries.generated';
import { entitlementLookup } from '../../../main/entitlementService';
import { useSearchPortalUsersQuery } from '../../users/queries.generated';
import { getI18n } from '../../../utils/Utils';
import { getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners } from 'src/main/__generated__/getUserEntitlementResultByUserId';
import { SearchOutlined } from '@ant-design/icons';

const PARTNER_RULES_TABLE_HEADERS = [
    { headerName: 'Partner No', field: 'partnerNo', colId: 'partnerNo', cellRenderer: 'agGroupCellRenderer' },
    { headerName: 'Partner Name', field: 'partnerName', colId: 'partnerName' },
    { headerName: 'Category', field: 'category', colId: 'category' },
];

const GROUP_DETAIL_TABLE_HEADERS = [
    { headerName: 'Entitlement Group', field: 'groupId', colId: 'groupId', cellRenderer: 'agGroupCellRenderer' },
    { headerName: 'Group Description', field: 'description', colId: 'description' },
];

const DETAIL_TABLE_ENTITLEMENT_HEADERS = [
    { headerName: 'Rule Id', field: 'ruleId', colId: 'ruleId' },
    { headerName: 'Allowed / Blocked', field: 'allowedOrBlocked', colId: 'allowedOrBlocked' },
    // { headerName: "Partner List", field: "partner", coldId: "partner" },
    { headerName: 'B2X Roles', field: 'b2xRole', colId: 'b2xRole' },
    { headerName: 'Department Codes', field: 'department', colId: 'department' },
    { headerName: 'Company Codes', field: 'company', colId: 'company' },
    { headerName: 'Object Class', field: 'objectClass', colId: 'objectClass' },
    { headerName: 'User ID', field: 'userId', colId: 'userId' },
    { headerName: 'Other Entitlements on Rule', field: 'otherEntitlementsOnRule', colId: 'otherEntitlementsOnRule' },
    { headerName: 'Rule Description', field: 'ruleDescription', colId: 'ruleDescription' },
    { headerName: 'Present in Groups', field: 'groupIds', colId: 'groupIds' },
];

type EntitlementFormProps = {
    onLoading: any;
} & IAppComponentProps;

type GroupList = {
    groupId: string;
    description: string;
    details: any[];
};

type PartnerRecord = {
    partnerNo: string;
    partnerName: string;
    category: string;
    groupList: GroupList[];
};

const AppliedPartnerEntitlementsComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();
    const [ruleIds, setRuleIds] = useState<number[]>([]);
    const [partnerList, setPartnerList] = useState<
        getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners[]
    >([]);
    const [partnerRecords, setPartnerRecords] = useState<PartnerRecord[]>([]);
    const [groupDetailGridApi, setGroupDetailGridApi] = useState<GridApi>(undefined);
    const [detailGridApi, setDetailGridApi] = useState<GridApi>(undefined);
    const [masterGridApi, setMasterGridApi] = useState<GridApi>(undefined);
    const [selectedDetailRow, setSelectedDetailRow] = useState(null);
    const [selectedMasterRow, setSelectedMasterRow] = useState(null);
    const [entitlementsLoading, setEntitlementsLoading] = useState<boolean>(false);
    const [userIdentifier, setUserIdentifier] = useState('');
    const [userId, setUserId] = useState('');
    const [debouncedUserId, setDebouncedUserId] = useState(userIdentifier);
    const [userInput, setUserInput] = useState<PortalUser>(undefined);
    const [gridHeight, setGridHeight] = useState(200); // Initial height

    const handleFormSearch = (values) => {
        // console.log('Form search Values:', form.getFieldsValue());
        if (userId !== undefined && userId !== '') {
            setEntitlementsLoading(true);
            entitlementLookup(userId)
                .then((result) => {
                    // console.log('Entitlement Lookup Result:', result);
                    if (result) {
                        // console.log('Entitlement Lookup Result:', result);
                        setEntitlementsLoading(false);
                        setPartnerList(result.getUserEntitlementResultByUserId.partners);
                        //get all applied rules
                        setRuleIds(result.getUserEntitlementResultByUserId.appliedRuleIds);
                    }
                })
                .catch((error) => {
                    console.error('Entitlement Lookup Error:', error);
                    message.error('Entitlement Lookup Error:');
                    setEntitlementsLoading(false);
                });
        }
    };

    const {
        data: entitlementRuleRowData,
        loading: entitlementReportLoading,
        error,
    } = useGetEntitlementReportByRuleIdsQuery({
        variables: {
            ruleIds,
        },
        skip: !ruleIds || ruleIds.length === 0, // Skip the query if ruleIds is undefined or empty
    });

    const {
        data: userData,
        loading: searchPortalUsersLoading,
        error: searchPortalUsersError,
    } = useSearchPortalUsersQuery({
        variables: {
            portalUserInput: userInput,
        },
        skip: !userInput,
    });

    if (error || searchPortalUsersError) {
        message.error(`Error loading data: ${error.message || searchPortalUsersError.message}`);
    }

    props.onLoading(entitlementReportLoading || entitlementsLoading || searchPortalUsersLoading);

    const onMasterGridReady = (params) => {
        // console.log('onMasterGridReady ready');
        setMasterGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onGroupDetailGridReady = (params) => {
        // console.log('onGroupDetailGridReady ready');
        setGroupDetailGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onDetailGridReady = (params) => {
        // console.log('onDetailGridReady ready');
        setDetailGridApi(params.api);
        params.api.sizeColumnsToFit();
        //adjustGridHeight(params.api);
    };

    const selectMasterRow = () => {
        // console.log('Selected Master Row:');
        // console.dir(masterGridApi!.getSelectedRows());
        const selectedRows = masterGridApi?.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedMasterRow(selectedRows[0]);
        } else {
            setSelectedMasterRow(null);
        }
    };

    const selectGroupDetailRow = () => {
        // console.log('Selected Detail Row:');
        // console.dir(detailGridApi!.getSelectedRows());
        const selectedRows = groupDetailGridApi?.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedDetailRow(selectedRows[0]);
        } else {
            setSelectedDetailRow(null);
        }
    };

    const detailGridOptions = useMemo(
        () => ({
            rowSelection: 'single',
            suppressRowClickSelection: true,
            enableRangeSelection: false,
            pagination: true,
            paginationAutoPageSize: false,
            paginationPageSize: 3,
            onGridReady: onDetailGridReady,
            columnDefs: DETAIL_TABLE_ENTITLEMENT_HEADERS,
            defaultColDef: {
                sortable: true,
                flex: 1,
                resizable: true,
            },
        }),
        []
    );

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions,
            getDetailRowData: (params) =>
                params.successCallback(params.data.details === null ? [] : params.data.details),
        }),
        []
    );

    const groupDetailGridOptions = useMemo(
        () => ({
            rowSelection: 'single',
            suppressRowClickSelection: true,
            enableRangeSelection: false,
            onRowSelected: selectGroupDetailRow,
            onGridReady: onGroupDetailGridReady,
            columnDefs: GROUP_DETAIL_TABLE_HEADERS,
            masterDetail: true,
            detailCellRendererParams,
            pagination: true,
            paginationAutoPageSize: true,
            defaultColDef: {
                sortable: true,
                flex: 1,
                resizable: true,
            },
        }),
        []
    );

    const groupDetailCellRendererParams = useMemo(
        () => ({
            detailGridOptions: groupDetailGridOptions,
            getDetailRowData: (params) =>
                params.successCallback(params.data.groupList === null ? [] : params.data.groupList),
        }),
        []
    );

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedUserId(userIdentifier);
            form.setFieldsValue({ userId: undefined });
        }, 1000); // 500ms delay

        return () => {
            clearTimeout(handler);
        };
    }, [userIdentifier]);

    useEffect(() => {
        if (debouncedUserId) {
            // Call user lookup service
            // console.log('User Id changed:', debouncedUserId);
            setUserInput({
                username: debouncedUserId,
                email: debouncedUserId,
                firstname: debouncedUserId,
                lastname: debouncedUserId,
            });
        }
    }, [debouncedUserId]);

    const onUserIdChange = (e) => {
        setUserIdentifier(e.target.value);
        //make sure grid is cleared
        setRuleIds([]);
    };

    useEffect(() => {
        if (entitlementRuleRowData) {
            //fill detail columns
            const partnerRecordList: PartnerRecord[] = [];

            partnerList.forEach((partner) => {
                let groupList: GroupList[] = [];
                groupList = entitlementRuleRowData.getEntitlementReportByRuleIds.map((rule) => {
                    const group: GroupList = {
                        groupId: rule.groupId,
                        description: rule.description,
                        details: rule.details,
                    };
                    return group;
                });
                const partnerRecord: PartnerRecord = {
                    partnerNo: partner.partnerNo,
                    partnerName: partner.partnerName,
                    category: partner.category,
                    groupList,
                };
                partnerRecordList.push(partnerRecord);
            });
            console.dir(partnerRecordList);
            setPartnerRecords(partnerRecordList);
        }
    }, [entitlementReportLoading, entitlementRuleRowData]);

    return (
        <Card title={'Check Applied Entitlement for User Id: '.concat(userId)}>
            <Form form={form} layout="vertical">
                <Row gutter={24} className={'pl20'}>
                    {userData?.searchPortalUsers?.length > 0 ? (
                        <Col span={2}>
                            <Button
                                type="primary"
                                onClick={handleFormSearch}
                                className={'mr5'}
                                icon={<SearchOutlined />}
                            >
                                {'Search for user Id '.concat(userId)}
                            </Button>
                        </Col>
                    ) : null}
                </Row>

                <Row gutter={24} className={'pl20'}>
                    <Col span={8}>
                        <Form.Item name="userIdentifier" label="User Identifier">
                            <Input
                                placeholder={getI18n(
                                    'entitlement-applied-user-search-user-identifier-placeholder',
                                    'Login name, Email or user name',
                                    props.intl
                                )}
                                allowClear={true}
                                onChange={onUserIdChange}
                            />
                        </Form.Item>
                    </Col>
                    {userData?.searchPortalUsers && userData?.searchPortalUsers.length > 0 ? (
                        <Col span={8}>
                            <Form.Item name="userId" label="Select User">
                                <Select
                                    placeholder={getI18n(
                                        'entitlement-applied-user-search-placeholder',
                                        'Select User',
                                        props.intl
                                    )}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                    }
                                    optionFilterProp="children"
                                    showSearch={true}
                                    data-testid={'userIdentifier'}
                                    onChange={(value) => setUserId(value)}
                                >
                                    {userData?.searchPortalUsers?.map((user) => (
                                        <Select.Option
                                            key={`${user.__typename}_${user.userId}`}
                                            value={user.userName}
                                            data-testid={user.userId}
                                        >
                                            {user.firstName
                                                .concat(' ')
                                                .concat(user.lastName)
                                                .concat(' - ')
                                                .concat(' (')
                                                .concat(user.userName)
                                                .concat(')')
                                                .concat(' - ')
                                                .concat(' (')
                                                .concat(user.objectClass)
                                                .concat(')')
                                                .concat(' - ')
                                                .concat(' (')
                                                .concat(user.employeeType)
                                                .concat(')')}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>
            </Form>
            <Row gutter={24}>
                <Col span={20} className="mb5">
                    <div style={{ flex: 'auto', height: 500, paddingTop: '0px' }} className="ag-theme-balham">
                        <AgGridReact
                            defaultColDef={{
                                enableValue: true,
                                sortable: true,
                                resizable: true,
                            }}
                            columnDefs={PARTNER_RULES_TABLE_HEADERS}
                            rowData={partnerRecords || []}
                            localeText={getAgGridLocalization(props.intl)}
                            sideBar={false}
                            rowClass="agGridRows"
                            rowSelection="single"
                            suppressMenuHide={true}
                            debug={false}
                            pagination={true}
                            paginationPageSize={15}
                            suppressRowClickSelection={true}
                            onGridReady={onMasterGridReady}
                            onRowSelected={selectMasterRow}
                            masterDetail={true}
                            detailCellRendererParams={groupDetailCellRendererParams}
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

export default AppliedPartnerEntitlementsComponent;